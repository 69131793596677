// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-404-tsx": () => import("./../../../src/pages/404/404.tsx" /* webpackChunkName: "component---src-pages-404-404-tsx" */),
  "component---src-pages-404-index-ts": () => import("./../../../src/pages/404/index.ts" /* webpackChunkName: "component---src-pages-404-index-ts" */),
  "component---src-pages-about-us-about-us-tsx": () => import("./../../../src/pages/about-us/aboutUs.tsx" /* webpackChunkName: "component---src-pages-about-us-about-us-tsx" */),
  "component---src-pages-about-us-index-ts": () => import("./../../../src/pages/about-us/index.ts" /* webpackChunkName: "component---src-pages-about-us-index-ts" */),
  "component---src-pages-acknowledgement-and-acceptance-acknowledgement-and-acceptance-tsx": () => import("./../../../src/pages/acknowledgement-and-acceptance/acknowledgementAndAcceptance.tsx" /* webpackChunkName: "component---src-pages-acknowledgement-and-acceptance-acknowledgement-and-acceptance-tsx" */),
  "component---src-pages-acknowledgement-and-acceptance-index-ts": () => import("./../../../src/pages/acknowledgement-and-acceptance/index.ts" /* webpackChunkName: "component---src-pages-acknowledgement-and-acceptance-index-ts" */),
  "component---src-pages-blog-blog-tsx": () => import("./../../../src/pages/blog/Blog.tsx" /* webpackChunkName: "component---src-pages-blog-blog-tsx" */),
  "component---src-pages-blog-building-a-remote-development-team-blog-article-page-tsx": () => import("./../../../src/pages/blog/building-a-remote-development-team/BlogArticlePage.tsx" /* webpackChunkName: "component---src-pages-blog-building-a-remote-development-team-blog-article-page-tsx" */),
  "component---src-pages-blog-building-a-remote-development-team-index-ts": () => import("./../../../src/pages/blog/building-a-remote-development-team/index.ts" /* webpackChunkName: "component---src-pages-blog-building-a-remote-development-team-index-ts" */),
  "component---src-pages-blog-co-innovation-in-action-blog-article-page-tsx": () => import("./../../../src/pages/blog/co-innovation-in-action/BlogArticlePage.tsx" /* webpackChunkName: "component---src-pages-blog-co-innovation-in-action-blog-article-page-tsx" */),
  "component---src-pages-blog-co-innovation-in-action-index-ts": () => import("./../../../src/pages/blog/co-innovation-in-action/index.ts" /* webpackChunkName: "component---src-pages-blog-co-innovation-in-action-index-ts" */),
  "component---src-pages-blog-index-ts": () => import("./../../../src/pages/blog/index.ts" /* webpackChunkName: "component---src-pages-blog-index-ts" */),
  "component---src-pages-blog-posts-blog-posts-tsx": () => import("./../../../src/pages/blog/posts/blogPosts.tsx" /* webpackChunkName: "component---src-pages-blog-posts-blog-posts-tsx" */),
  "component---src-pages-blog-posts-index-ts": () => import("./../../../src/pages/blog/posts/index.ts" /* webpackChunkName: "component---src-pages-blog-posts-index-ts" */),
  "component---src-pages-blog-say-goodbye-to-third-party-cookies-blog-article-page-tsx": () => import("./../../../src/pages/blog/say-goodbye-to-third-party-cookies/BlogArticlePage.tsx" /* webpackChunkName: "component---src-pages-blog-say-goodbye-to-third-party-cookies-blog-article-page-tsx" */),
  "component---src-pages-blog-say-goodbye-to-third-party-cookies-index-ts": () => import("./../../../src/pages/blog/say-goodbye-to-third-party-cookies/index.ts" /* webpackChunkName: "component---src-pages-blog-say-goodbye-to-third-party-cookies-index-ts" */),
  "component---src-pages-blog-studies-blog-studies-tsx": () => import("./../../../src/pages/blog/studies/blogStudies.tsx" /* webpackChunkName: "component---src-pages-blog-studies-blog-studies-tsx" */),
  "component---src-pages-blog-studies-index-ts": () => import("./../../../src/pages/blog/studies/index.ts" /* webpackChunkName: "component---src-pages-blog-studies-index-ts" */),
  "component---src-pages-blog-transforming-industries-with-web-3-blog-article-page-tsx": () => import("./../../../src/pages/blog/transforming-industries-with-web-3/BlogArticlePage.tsx" /* webpackChunkName: "component---src-pages-blog-transforming-industries-with-web-3-blog-article-page-tsx" */),
  "component---src-pages-blog-transforming-industries-with-web-3-index-ts": () => import("./../../../src/pages/blog/transforming-industries-with-web-3/index.ts" /* webpackChunkName: "component---src-pages-blog-transforming-industries-with-web-3-index-ts" */),
  "component---src-pages-blog-unlocking-the-power-of-managed-service-blog-article-page-tsx": () => import("./../../../src/pages/blog/unlocking-the-power-of-managed-service/BlogArticlePage.tsx" /* webpackChunkName: "component---src-pages-blog-unlocking-the-power-of-managed-service-blog-article-page-tsx" */),
  "component---src-pages-blog-unlocking-the-power-of-managed-service-index-ts": () => import("./../../../src/pages/blog/unlocking-the-power-of-managed-service/index.ts" /* webpackChunkName: "component---src-pages-blog-unlocking-the-power-of-managed-service-index-ts" */),
  "component---src-pages-blog-videos-index-ts": () => import("./../../../src/pages/blog/videos/index.ts" /* webpackChunkName: "component---src-pages-blog-videos-index-ts" */),
  "component---src-pages-blog-videos-videos-tsx": () => import("./../../../src/pages/blog/videos/Videos.tsx" /* webpackChunkName: "component---src-pages-blog-videos-videos-tsx" */),
  "component---src-pages-contact-us-contact-us-tsx": () => import("./../../../src/pages/contact-us/contactUs.tsx" /* webpackChunkName: "component---src-pages-contact-us-contact-us-tsx" */),
  "component---src-pages-contact-us-index-ts": () => import("./../../../src/pages/contact-us/index.ts" /* webpackChunkName: "component---src-pages-contact-us-index-ts" */),
  "component---src-pages-freshii-case-study-freshii-case-study-tsx": () => import("./../../../src/pages/freshii-case-study/FreshiiCaseStudy.tsx" /* webpackChunkName: "component---src-pages-freshii-case-study-freshii-case-study-tsx" */),
  "component---src-pages-freshii-case-study-full-credits-full-credits-tsx": () => import("./../../../src/pages/freshii-case-study/full-credits/FullCredits.tsx" /* webpackChunkName: "component---src-pages-freshii-case-study-full-credits-full-credits-tsx" */),
  "component---src-pages-freshii-case-study-full-credits-index-ts": () => import("./../../../src/pages/freshii-case-study/full-credits/index.ts" /* webpackChunkName: "component---src-pages-freshii-case-study-full-credits-index-ts" */),
  "component---src-pages-freshii-case-study-index-ts": () => import("./../../../src/pages/freshii-case-study/index.ts" /* webpackChunkName: "component---src-pages-freshii-case-study-index-ts" */),
  "component---src-pages-freshii-case-study-other-industries-relevance-index-ts": () => import("./../../../src/pages/freshii-case-study/other-industries-relevance/index.ts" /* webpackChunkName: "component---src-pages-freshii-case-study-other-industries-relevance-index-ts" */),
  "component---src-pages-freshii-case-study-other-industries-relevance-other-industries-relevance-tsx": () => import("./../../../src/pages/freshii-case-study/other-industries-relevance/OtherIndustriesRelevance.tsx" /* webpackChunkName: "component---src-pages-freshii-case-study-other-industries-relevance-other-industries-relevance-tsx" */),
  "component---src-pages-freshii-case-study-project-gallery-index-ts": () => import("./../../../src/pages/freshii-case-study/project-gallery/index.ts" /* webpackChunkName: "component---src-pages-freshii-case-study-project-gallery-index-ts" */),
  "component---src-pages-freshii-case-study-project-gallery-project-gallery-tsx": () => import("./../../../src/pages/freshii-case-study/project-gallery/ProjectGallery.tsx" /* webpackChunkName: "component---src-pages-freshii-case-study-project-gallery-project-gallery-tsx" */),
  "component---src-pages-freshii-case-study-scope-of-project-index-ts": () => import("./../../../src/pages/freshii-case-study/scope-of-project/index.ts" /* webpackChunkName: "component---src-pages-freshii-case-study-scope-of-project-index-ts" */),
  "component---src-pages-freshii-case-study-scope-of-project-scope-of-project-tsx": () => import("./../../../src/pages/freshii-case-study/scope-of-project/ScopeOfProject.tsx" /* webpackChunkName: "component---src-pages-freshii-case-study-scope-of-project-scope-of-project-tsx" */),
  "component---src-pages-freshii-case-study-technical-summary-index-ts": () => import("./../../../src/pages/freshii-case-study/technical-summary/index.ts" /* webpackChunkName: "component---src-pages-freshii-case-study-technical-summary-index-ts" */),
  "component---src-pages-freshii-case-study-technical-summary-technical-summary-tsx": () => import("./../../../src/pages/freshii-case-study/technical-summary/TechnicalSummary.tsx" /* webpackChunkName: "component---src-pages-freshii-case-study-technical-summary-technical-summary-tsx" */),
  "component---src-pages-how-we-work-engagement-models-index-ts": () => import("./../../../src/pages/how-we-work/engagement-models/index.ts" /* webpackChunkName: "component---src-pages-how-we-work-engagement-models-index-ts" */),
  "component---src-pages-how-we-work-engagement-models-working-together-tsx": () => import("./../../../src/pages/how-we-work/engagement-models/workingTogether.tsx" /* webpackChunkName: "component---src-pages-how-we-work-engagement-models-working-together-tsx" */),
  "component---src-pages-how-we-work-how-we-work-tsx": () => import("./../../../src/pages/how-we-work/howWeWork.tsx" /* webpackChunkName: "component---src-pages-how-we-work-how-we-work-tsx" */),
  "component---src-pages-how-we-work-index-ts": () => import("./../../../src/pages/how-we-work/index.ts" /* webpackChunkName: "component---src-pages-how-we-work-index-ts" */),
  "component---src-pages-how-we-work-intellectual-property-index-ts": () => import("./../../../src/pages/how-we-work/intellectual-property/index.ts" /* webpackChunkName: "component---src-pages-how-we-work-intellectual-property-index-ts" */),
  "component---src-pages-how-we-work-intellectual-property-intellectual-property-tsx": () => import("./../../../src/pages/how-we-work/intellectual-property/intellectualProperty.tsx" /* webpackChunkName: "component---src-pages-how-we-work-intellectual-property-intellectual-property-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-index-ts": () => import("./../../../src/pages/privacy-policy/index.ts" /* webpackChunkName: "component---src-pages-privacy-policy-index-ts" */),
  "component---src-pages-privacy-policy-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy/privacyPolicy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-privacy-policy-tsx" */),
  "component---src-pages-spectar-case-study-full-credits-full-credits-tsx": () => import("./../../../src/pages/spectar-case-study/full-credits/FullCredits.tsx" /* webpackChunkName: "component---src-pages-spectar-case-study-full-credits-full-credits-tsx" */),
  "component---src-pages-spectar-case-study-full-credits-index-ts": () => import("./../../../src/pages/spectar-case-study/full-credits/index.ts" /* webpackChunkName: "component---src-pages-spectar-case-study-full-credits-index-ts" */),
  "component---src-pages-spectar-case-study-index-ts": () => import("./../../../src/pages/spectar-case-study/index.ts" /* webpackChunkName: "component---src-pages-spectar-case-study-index-ts" */),
  "component---src-pages-spectar-case-study-other-industries-relevance-index-ts": () => import("./../../../src/pages/spectar-case-study/other-industries-relevance/index.ts" /* webpackChunkName: "component---src-pages-spectar-case-study-other-industries-relevance-index-ts" */),
  "component---src-pages-spectar-case-study-other-industries-relevance-other-industries-relevance-tsx": () => import("./../../../src/pages/spectar-case-study/other-industries-relevance/OtherIndustriesRelevance.tsx" /* webpackChunkName: "component---src-pages-spectar-case-study-other-industries-relevance-other-industries-relevance-tsx" */),
  "component---src-pages-spectar-case-study-project-gallery-index-ts": () => import("./../../../src/pages/spectar-case-study/project-gallery/index.ts" /* webpackChunkName: "component---src-pages-spectar-case-study-project-gallery-index-ts" */),
  "component---src-pages-spectar-case-study-project-gallery-project-gallery-tsx": () => import("./../../../src/pages/spectar-case-study/project-gallery/ProjectGallery.tsx" /* webpackChunkName: "component---src-pages-spectar-case-study-project-gallery-project-gallery-tsx" */),
  "component---src-pages-spectar-case-study-scope-of-project-index-ts": () => import("./../../../src/pages/spectar-case-study/scope-of-project/index.ts" /* webpackChunkName: "component---src-pages-spectar-case-study-scope-of-project-index-ts" */),
  "component---src-pages-spectar-case-study-scope-of-project-scope-of-project-tsx": () => import("./../../../src/pages/spectar-case-study/scope-of-project/ScopeOfProject.tsx" /* webpackChunkName: "component---src-pages-spectar-case-study-scope-of-project-scope-of-project-tsx" */),
  "component---src-pages-spectar-case-study-spectar-case-study-tsx": () => import("./../../../src/pages/spectar-case-study/SpectarCaseStudy.tsx" /* webpackChunkName: "component---src-pages-spectar-case-study-spectar-case-study-tsx" */),
  "component---src-pages-spectar-case-study-technical-summary-index-ts": () => import("./../../../src/pages/spectar-case-study/technical-summary/index.ts" /* webpackChunkName: "component---src-pages-spectar-case-study-technical-summary-index-ts" */),
  "component---src-pages-spectar-case-study-technical-summary-technical-summary-tsx": () => import("./../../../src/pages/spectar-case-study/technical-summary/TechnicalSummary.tsx" /* webpackChunkName: "component---src-pages-spectar-case-study-technical-summary-technical-summary-tsx" */),
  "component---src-pages-statflo-case-study-full-credits-full-credits-tsx": () => import("./../../../src/pages/statflo-case-study/full-credits/FullCredits.tsx" /* webpackChunkName: "component---src-pages-statflo-case-study-full-credits-full-credits-tsx" */),
  "component---src-pages-statflo-case-study-full-credits-index-ts": () => import("./../../../src/pages/statflo-case-study/full-credits/index.ts" /* webpackChunkName: "component---src-pages-statflo-case-study-full-credits-index-ts" */),
  "component---src-pages-statflo-case-study-index-ts": () => import("./../../../src/pages/statflo-case-study/index.ts" /* webpackChunkName: "component---src-pages-statflo-case-study-index-ts" */),
  "component---src-pages-statflo-case-study-other-industries-relevance-index-ts": () => import("./../../../src/pages/statflo-case-study/other-industries-relevance/index.ts" /* webpackChunkName: "component---src-pages-statflo-case-study-other-industries-relevance-index-ts" */),
  "component---src-pages-statflo-case-study-other-industries-relevance-other-industries-relevance-tsx": () => import("./../../../src/pages/statflo-case-study/other-industries-relevance/OtherIndustriesRelevance.tsx" /* webpackChunkName: "component---src-pages-statflo-case-study-other-industries-relevance-other-industries-relevance-tsx" */),
  "component---src-pages-statflo-case-study-project-gallery-index-ts": () => import("./../../../src/pages/statflo-case-study/project-gallery/index.ts" /* webpackChunkName: "component---src-pages-statflo-case-study-project-gallery-index-ts" */),
  "component---src-pages-statflo-case-study-project-gallery-project-gallery-tsx": () => import("./../../../src/pages/statflo-case-study/project-gallery/ProjectGallery.tsx" /* webpackChunkName: "component---src-pages-statflo-case-study-project-gallery-project-gallery-tsx" */),
  "component---src-pages-statflo-case-study-scope-of-project-index-ts": () => import("./../../../src/pages/statflo-case-study/scope-of-project/index.ts" /* webpackChunkName: "component---src-pages-statflo-case-study-scope-of-project-index-ts" */),
  "component---src-pages-statflo-case-study-scope-of-project-scope-of-project-tsx": () => import("./../../../src/pages/statflo-case-study/scope-of-project/ScopeOfProject.tsx" /* webpackChunkName: "component---src-pages-statflo-case-study-scope-of-project-scope-of-project-tsx" */),
  "component---src-pages-statflo-case-study-statflo-case-study-tsx": () => import("./../../../src/pages/statflo-case-study/StatfloCaseStudy.tsx" /* webpackChunkName: "component---src-pages-statflo-case-study-statflo-case-study-tsx" */),
  "component---src-pages-statflo-case-study-technical-summary-index-ts": () => import("./../../../src/pages/statflo-case-study/technical-summary/index.ts" /* webpackChunkName: "component---src-pages-statflo-case-study-technical-summary-index-ts" */),
  "component---src-pages-statflo-case-study-technical-summary-technical-summary-tsx": () => import("./../../../src/pages/statflo-case-study/technical-summary/TechnicalSummary.tsx" /* webpackChunkName: "component---src-pages-statflo-case-study-technical-summary-technical-summary-tsx" */),
  "component---src-pages-walmart-case-study-full-credits-full-credits-tsx": () => import("./../../../src/pages/walmart-case-study/full-credits/FullCredits.tsx" /* webpackChunkName: "component---src-pages-walmart-case-study-full-credits-full-credits-tsx" */),
  "component---src-pages-walmart-case-study-full-credits-index-ts": () => import("./../../../src/pages/walmart-case-study/full-credits/index.ts" /* webpackChunkName: "component---src-pages-walmart-case-study-full-credits-index-ts" */),
  "component---src-pages-walmart-case-study-index-ts": () => import("./../../../src/pages/walmart-case-study/index.ts" /* webpackChunkName: "component---src-pages-walmart-case-study-index-ts" */),
  "component---src-pages-walmart-case-study-other-industries-relevance-index-ts": () => import("./../../../src/pages/walmart-case-study/other-industries-relevance/index.ts" /* webpackChunkName: "component---src-pages-walmart-case-study-other-industries-relevance-index-ts" */),
  "component---src-pages-walmart-case-study-other-industries-relevance-other-industries-relevance-tsx": () => import("./../../../src/pages/walmart-case-study/other-industries-relevance/OtherIndustriesRelevance.tsx" /* webpackChunkName: "component---src-pages-walmart-case-study-other-industries-relevance-other-industries-relevance-tsx" */),
  "component---src-pages-walmart-case-study-project-gallery-index-ts": () => import("./../../../src/pages/walmart-case-study/project-gallery/index.ts" /* webpackChunkName: "component---src-pages-walmart-case-study-project-gallery-index-ts" */),
  "component---src-pages-walmart-case-study-project-gallery-project-gallery-tsx": () => import("./../../../src/pages/walmart-case-study/project-gallery/ProjectGallery.tsx" /* webpackChunkName: "component---src-pages-walmart-case-study-project-gallery-project-gallery-tsx" */),
  "component---src-pages-walmart-case-study-scope-of-project-index-ts": () => import("./../../../src/pages/walmart-case-study/scope-of-project/index.ts" /* webpackChunkName: "component---src-pages-walmart-case-study-scope-of-project-index-ts" */),
  "component---src-pages-walmart-case-study-scope-of-project-scope-of-project-tsx": () => import("./../../../src/pages/walmart-case-study/scope-of-project/ScopeOfProject.tsx" /* webpackChunkName: "component---src-pages-walmart-case-study-scope-of-project-scope-of-project-tsx" */),
  "component---src-pages-walmart-case-study-technical-summary-index-ts": () => import("./../../../src/pages/walmart-case-study/technical-summary/index.ts" /* webpackChunkName: "component---src-pages-walmart-case-study-technical-summary-index-ts" */),
  "component---src-pages-walmart-case-study-technical-summary-technical-summary-tsx": () => import("./../../../src/pages/walmart-case-study/technical-summary/TechnicalSummary.tsx" /* webpackChunkName: "component---src-pages-walmart-case-study-technical-summary-technical-summary-tsx" */),
  "component---src-pages-walmart-case-study-walmart-case-study-tsx": () => import("./../../../src/pages/walmart-case-study/WalmartCaseStudy.tsx" /* webpackChunkName: "component---src-pages-walmart-case-study-walmart-case-study-tsx" */),
  "component---src-pages-what-we-do-ar-vr-ar-vr-tsx": () => import("./../../../src/pages/what-we-do/ar-&-vr/ArVr.tsx" /* webpackChunkName: "component---src-pages-what-we-do-ar-vr-ar-vr-tsx" */),
  "component---src-pages-what-we-do-ar-vr-index-ts": () => import("./../../../src/pages/what-we-do/ar-&-vr/index.ts" /* webpackChunkName: "component---src-pages-what-we-do-ar-vr-index-ts" */),
  "component---src-pages-what-we-do-cloud-services-cloud-services-tsx": () => import("./../../../src/pages/what-we-do/cloud-services/CloudServices.tsx" /* webpackChunkName: "component---src-pages-what-we-do-cloud-services-cloud-services-tsx" */),
  "component---src-pages-what-we-do-cloud-services-index-ts": () => import("./../../../src/pages/what-we-do/cloud-services/index.ts" /* webpackChunkName: "component---src-pages-what-we-do-cloud-services-index-ts" */),
  "component---src-pages-what-we-do-index-ts": () => import("./../../../src/pages/what-we-do/index.ts" /* webpackChunkName: "component---src-pages-what-we-do-index-ts" */),
  "component---src-pages-what-we-do-mobile-development-index-ts": () => import("./../../../src/pages/what-we-do/mobile-development/index.ts" /* webpackChunkName: "component---src-pages-what-we-do-mobile-development-index-ts" */),
  "component---src-pages-what-we-do-mobile-development-mobile-development-tsx": () => import("./../../../src/pages/what-we-do/mobile-development/MobileDevelopment.tsx" /* webpackChunkName: "component---src-pages-what-we-do-mobile-development-mobile-development-tsx" */),
  "component---src-pages-what-we-do-retail-and-e-commerce-index-ts": () => import("./../../../src/pages/what-we-do/retail-and-e-commerce/index.ts" /* webpackChunkName: "component---src-pages-what-we-do-retail-and-e-commerce-index-ts" */),
  "component---src-pages-what-we-do-retail-and-e-commerce-retail-and-ecommerce-tsx": () => import("./../../../src/pages/what-we-do/retail-and-e-commerce/RetailAndEcommerce.tsx" /* webpackChunkName: "component---src-pages-what-we-do-retail-and-e-commerce-retail-and-ecommerce-tsx" */),
  "component---src-pages-what-we-do-team-extension-index-ts": () => import("./../../../src/pages/what-we-do/team-extension/index.ts" /* webpackChunkName: "component---src-pages-what-we-do-team-extension-index-ts" */),
  "component---src-pages-what-we-do-team-extension-team-extension-tsx": () => import("./../../../src/pages/what-we-do/team-extension/TeamExtension.tsx" /* webpackChunkName: "component---src-pages-what-we-do-team-extension-team-extension-tsx" */),
  "component---src-pages-what-we-do-web-development-index-ts": () => import("./../../../src/pages/what-we-do/web-development/index.ts" /* webpackChunkName: "component---src-pages-what-we-do-web-development-index-ts" */),
  "component---src-pages-what-we-do-web-development-web-development-tsx": () => import("./../../../src/pages/what-we-do/web-development/webDevelopment.tsx" /* webpackChunkName: "component---src-pages-what-we-do-web-development-web-development-tsx" */),
  "component---src-pages-what-we-do-what-we-do-tsx": () => import("./../../../src/pages/what-we-do/whatWeDo.tsx" /* webpackChunkName: "component---src-pages-what-we-do-what-we-do-tsx" */)
}


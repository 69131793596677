exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    const { pathname } = location;
    // list of routes for the scroll-to-top-hook
    const scrollToTopRoutes = [
        '/what-we-do/',
        '/how-we-work/',
        '/how-we-work/engagement-models/',
        '/contact-us',
        '/how-we-work/intellectual-property/',
        '/about-us/',
        '/blog/studies/',
        '/',
        '/acknowledgement-and-acceptance',
        '/blog/say-goodbye-to-third-party-cookies',
    ];
    // if the new route is part of the list above, scroll to top (0, 0)
    if (scrollToTopRoutes.indexOf(pathname) !== -1) {
        window.scrollTo(0, 0);
    }

    return false;
};
